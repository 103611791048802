import React from "react";
import { useState } from "react";
import textDictionary from "assets/text_dictionary";
import iconList from "assets/icon_list";

import GridDropdown from 'react-grid-dropdown';
import DatePicker from "react-multi-date-picker";
import 'react-grid-dropdown/dist/style.css';

import {
    Col,
    Card
  } from "reactstrap";
 
///Title: The name of the study element being created via the form (ie, Multiple Choice Question)
///Template: The template to create the desired study element (ie, "multiple_choice_question": {"question": string, "etc": "etc"})
///Custom_Types: Custom data types that might be included in the template, typically nested into the Template
///Activities: A list of current activities, with their ordering information
///Callback: A callback fundtion to notify the parent of new values input to the form
///InitialValues: Any initial values to prepopulate the form with
///keyIndex: The unique number given to each instance of a form to ensure unique keys
function FormBuilder({title, template, custom_types, activities, callBack, initialValues, keyIndex}) {
    const [values, setValues] = useState(initialValues);
    const [doneBeforeState, setDoneBeforeState] = useState((typeof activities === 'undefined' ? [] : activities[keyIndex]['done_before']));
    const [doneAfterState, setDoneAfterState] = useState((typeof activities === 'undefined' ? [] : activities[keyIndex]['done_after']));

    //Returns any updates in the form to the parent of the form builder
    //Copies the new values for the form into the state for this component
    function onChange(value, key) {
        callBack(key, value);
        var nextValues = {...values};
        nextValues[key] = value;
        setValues(nextValues);
    }

    //helper function for callbacks to nested arrays
    function onChangeNestedArray(key, values) {
        if(key === 'done_before') setDoneBeforeState([...values]);
        else if (key === 'done_after') setDoneAfterState([...values]);
        callBack(key, [...values]);
    }


    //A helper function for onChange() to handle nested objects. Some template items, such as the "instructions", 
    //create nested values that need to be returned to the parent object through the provided callback.
    //However, onChange() can't handle nested functions. This function places the response value in the nested object,
    //then passes said nested object into onChange() as a single value.
    function onChangeNested(nestedValue, key, nestedKey) {
        var nextValues = {...values};
        var nextNestedValues = {...nextValues[key]};
        nextNestedValues[nestedKey] = nestedValue;
        onChange(nextNestedValues, key)
    }

    //A function used by the category of 'ordering' components [see the switch in templateToForm()].
    //It is used to keep track of any specific orders of activities.
    //TODO
    // function reOrderArray() {
        
    // }

    function templateToForm() {
        var rows = [];
        if(template != null) {
            var keys = Object.keys(template);
            for(var key of keys) {
                let keyCopy = key;
                //Run the callback once to store the key in question with a null value. That way complete responses to the backend are ensured.
                //callBack(key, initialValues[key]);
                switch(template[key]) {
                    //Tested!
                    case "string":
                        rows.push(<Col key={keyCopy + keyIndex + "col"}><label htmlFor={keyCopy}>{textDictionary[keyCopy]}: </label>
                            <input type="text" name={keyCopy} key={keyCopy + keyIndex + "text_input"}
                                onChange={(event) => onChange(event.target.value, keyCopy)} 
                                value={values[keyCopy]} className="form-control"></input></Col>);
                        break;
                    //Comma delineated MVP
                    case "string[]":
                        rows.push(<Col key={keyCopy + keyIndex + "col"}><label htmlFor={keyCopy}>{textDictionary[keyCopy]}: <i>(Comma Delineate Options: Option A, Option B, Option C)</i></label>
                            <input type="text" name={keyCopy} key={keyCopy + keyIndex + "text_input"}
                                onChange={(event) => onChange((event.target.value).split(','), keyCopy)} 
                                value={values[keyCopy]} className="form-control"></input></Col>);
                        break;
                    //Done, needs more valition work. Used as pagenumbers and question numbers. But, it will do for now as an MVP
                    case "int":
                        rows.push(<Col key={keyCopy + keyIndex + "col"}><label htmlFor={keyCopy}>{textDictionary[keyCopy]}: </label>
                            <input type="number" name={keyCopy} key={keyCopy + keyIndex + "text_input"}
                                onChange={(event) => onChange(parseInt(event.target.value), keyCopy)} 
                                value={values[keyCopy]} className="form-control" min="0" oninput="validity.valid||(value=0)"></input></Col>);
                        break;
                    //Comma delineated MVP
                    case "int[]":
                        rows.push(<Col key={keyCopy + keyIndex + "col"}><label htmlFor={keyCopy}>{textDictionary[keyCopy]}: <i>(Comma Delineate Options: 1, 2, 3)</i></label>
                            <input type="text" name={keyCopy} key={keyCopy + keyIndex + "text_input"}
                                onChange={(event) => onChange(event.target.value, keyCopy)} 
                                value={values[keyCopy]} className="form-control"></input></Col>);
                        break;
                    //Tested!
                    case "calendar_picker":
                        rows.push(<Col key={keyCopy + keyIndex + "col"}><label htmlFor={keyCopy}>{textDictionary[keyCopy]}: </label>
                        <input type="date" name={keyCopy} key={keyCopy + keyIndex + "date_input"} onChange={(event) => onChange(event.target.value, keyCopy)} value={values[keyCopy]} className="form-control"></input></Col>)
                        break;
                    //Tested!
                    case "boolean":
//TODO: FIX DB MISTAKE: //Check if this is a dynamic study and the key is 'start now'. If this is for the 'start now' field and it is not dynamic, do not render anything. This is a mistake in db design since the start_now field should be nested.
                        if(keyCopy === 'start_now' && activities[keyIndex]['type'] !== 'dynamic') break;
                        rows.push(
                            <Col className="form-inline" key={keyCopy + keyIndex + "col"}>
                                <label htmlFor={keyCopy}>{textDictionary[keyCopy]}: 
                                    <input type="checkbox" name={keyCopy} key={keyCopy + keyIndex + "radio_true_input"}
                                        id = {keyCopy + keyIndex + "radio_true_input"}
                                        onChange={(event) => {onChange(!(values[keyCopy] === true), keyCopy)}}
                                        checked={values[keyCopy]} className="form-control"
                                        style={{marginLeft:'3px', marginTop:'3px'}}> 
                                    </input>
                                </label>
                            </Col>);
                        break;
                    //
                    case "value/condition/param":
                        //A temporary holder for instructions so that they can be wrapped in a card and inserted
                        let validation = [];
                        
                        //Add a title to the subcard
                        validation.push(<Col>Validation (Leave empty if no validation needed)</Col>)

                        //Create an input for the value to validate against
                        validation.push(
                            <Col key={keyCopy + keyIndex + "value_col"}>
                                <label             htmlFor={keyCopy + 'value'}>Value: </label>
                                <input type="text" name   ={keyCopy + 'value'} key={keyCopy + keyIndex + "text_input"}
                                    onChange={(event) => onChangeNested(event.target.value, keyCopy, 'value')} 
                                    value={values[keyCopy]['value']} className="form-control"></input>
                            </Col>
                        );
                        //Create an input for the condition with which to compare the input value and validation value
                        //(greater than, less than, etc)
                        validation.push(
                            <Col key={keyCopy + keyIndex + "condition_col"}>
                                <label             htmlFor={keyCopy + 'condition'}>Condition:</label>
                                <input type="text" name   ={keyCopy + 'condition'} key={keyCopy + keyIndex + "text_input"}
                                    onChange={(event) => onChangeNested(event.target.value, keyCopy, 'condition')} 
                                    value={values[keyCopy]['condition']} className="form-control">
                                </input>
                            </Col>
                        );
                        //Create an input the parameter
                        validation.push(
                            <Col key={keyCopy + keyIndex + "param_col"}>
                                <label htmlFor={keyCopy + 'param'}>Parameter: </label>
                                <input type="text" name   ={keyCopy + 'param'} key={keyCopy + keyIndex + "text_area"}
                                    onChange={(event) => onChangeNested(event.target.value, keyCopy, 'param')} 
                                    value={values[keyCopy]['param']} className="form-control">
                                </input>
                                <br></br>
                            </Col>
                        );
                        
                        //Add a line break to space out this subcard from the previous line item
                        rows.push(<br></br>);

                        //Push the validation onto the list of form elements, wrapped in their own card
                        rows.push(<Card>{validation}</Card>);
                        break;
                    //Tested!
                    case "instructions":
                        //A temporary holder for instructions so that they can be wrapped in a card and inserted
                        let instructions = [];
                        //Add a title to the subcard
                        instructions.push(<text>Instructions</text>)

                        //Create an input for the icon url
                        instructions.push(
                            <Col key={keyCopy + keyIndex + "icon_col"}>
                                <label             htmlFor={keyCopy + 'icon'}>Icon URL: </label>
                                <input type="text" name   ={keyCopy + 'icon'} key={keyCopy + keyIndex + "text_input"}
                                    onChange={(event) => onChangeNested(event.target.value, keyCopy, 'icon')} 
                                    value={values[keyCopy]['icon']} className="form-control"></input>
                            </Col>
                        );
                        //Create an input for the title
                        instructions.push(
                            <Col key={keyCopy + keyIndex + "title_col"}>
                                <label             htmlFor={keyCopy + 'title'}>Title: </label>
                                <input type="text" name   ={keyCopy + 'title'} key={keyCopy + keyIndex + "text_input"}
                                    onChange={(event) => onChangeNested(event.target.value, keyCopy, 'title')} 
                                    value={values[keyCopy]['title']} className="form-control">
                                </input>
                            </Col>
                        );
                        //Create an input the instruction
                        instructions.push(
                            <Col key={keyCopy + keyIndex + "instruction_col"}>
                                <label                htmlFor={keyCopy + 'instruction'}>Instruction: </label>
                                <textarea type="text" name   ={keyCopy + 'instruction'} key={keyCopy + keyIndex + "text_area"}
                                    onChange={(event) => onChangeNested(event.target.value, keyCopy, 'instruction')} 
                                    value={values[keyCopy]['instruction']} className="form-control">
                                </textarea>
                            </Col>
                        );
                        //Push the instructions onto the list of form elements, wrapped in their own card
                        rows.push(<Card>{instructions}</Card>);
                        break;
                
                    case "dynamic_schedule":
                        switch(activities[keyIndex]['type']) {
                            case "open":
                                break;
                            case "dynamic":
                                let schedule = [];
                                schedule.push(<Col key={keyCopy + keyIndex + "col"}><label htmlFor={keyCopy}>Days Until Activity Starts:</label>
                                <input type="text" name={keyCopy} key={keyCopy + keyIndex + "text_input"}
                                    onChange={(event) => onChangeNested(event.target.value, keyCopy, 'Delay')} 
                                    value={values[keyCopy]['Delay']} className="form-control"></input></Col>);
                                schedule.push(<Col key={keyCopy + keyIndex + "col2"}><label htmlFor={keyCopy}>Activity Availability: (1 = Once daily, 2 = Once every other day, etc.)</label>
                                <input type="number" name={keyCopy} key={keyCopy + keyIndex + "text_input"}
                                    onChange={(event) => onChangeNested(event.target.value, keyCopy, 'Frequency')} 
                                    value={values[keyCopy]['Frequency']} className="form-control" min="0" oninput="validity.valid||(value='')"></input></Col>);
                                rows.push(<>{schedule}</>);
                                break;
                            case "scheduled":
                                var datesScheduled = [values[keyCopy]];
                                rows.push(
                                    <Col key={keyCopy + keyIndex + "col"}>
                                        <label htmlFor={keyCopy}>{textDictionary[keyCopy]}: </label>
                                        <br></br>
                                        <DatePicker key={keyCopy + keyIndex + "date_schedule_input"} onChange={(event) => {
                                            if(values[keyCopy].length > 1) {
                                                var nextDates = [];
                                                event.forEach(dateSelected => {
                                                    nextDates.push(dateSelected.year + '-' + dateSelected.month.number + '-' + dateSelected.day);
                                                });
                                                datesScheduled = nextDates;
                                            } else {
                                                datesScheduled.push(event.year + '-' + event.month.number + '-' + event.day);
                                            }
                                            onChange(datesScheduled, keyCopy);
                                        }} value={values[keyCopy]}/>
                                    </Col>
                                );
                                break;
                            case "single_response":
                                break;
                            default:
                                break;
                        }
                        break;
                    case "schedule_options":
                        var dropDownMenu = [];
                        for(var i = 0; i < custom_types['schedule_options'].length; i++) {
                            dropDownMenu.push(<option key={i + keyIndex + 'schedule_option'} value={custom_types['schedule_options'][i]}>{textDictionary[custom_types['schedule_options'][i]]}</option>)
                        }
                        rows.push(
                            <Col key={keyCopy + keyIndex + "col"}><label htmlFor={keyIndex + "schedule_select_list"}>{textDictionary[keyCopy]}: </label>
                            <br></br>
                            <select
                                onChange={(selection) => onChange(selection.target.value, keyCopy)} 
                                name={keyIndex + "schedule_select_list"}
                                id={keyIndex + "schedule_select_list"}
                                value={values[keyCopy]}
                            >{dropDownMenu}</select>
                            </Col>
                        );
                        break;
                    case "class_options":
                        var dropDownMenu = [];
                        for(var i = 0; i < custom_types['class_options'].length; i++) {
                            dropDownMenu.push(<option key={i + keyIndex + 'class_option'} value={custom_types['class_options'][i]}>{textDictionary[custom_types['class_options'][i]]}</option>)
                        }
                        rows.push(
                            <Col key={keyCopy + keyIndex + "col"}><label htmlFor={keyIndex + "class_select_list"}>{textDictionary[keyCopy]}: </label>
                            <br></br>
                            <select
                                onChange={(selection) => onChange(selection.target.value, keyCopy)} 
                                name={keyIndex + "class_select_list"}
                                id={keyIndex + "class_select_list"}
                                value={values[keyCopy]}
                            >{dropDownMenu}</select>
                            </Col>
                        );
                        break;
                    //ToDo
                    case "ordering":

                        //If there is only one activity, there is no need for ordering and this portion of the template should be skipped
                        if(activities.length === 1) break;

                        let doneAfterSelectionOptions = [];
                        let doneBeforeSelectionOptions = [];

                        for (var j = 0; j < activities.length; j++) {
                            //Skip over checking the element for this form (the user is ordering the other elements in context of this one, so don't show it as an option to change in the order of surveys)
                            if(j === keyIndex) continue;

                            //Add a check box for each option
                            //THIS DOES NOT UPDATE OTHER OBJECTS YET, ONLY THE CURRENT ACITIVITY INDICATED BY KEYINDEX PER LIMITES OF THE CALLBACK FUNCTION ONLY UPDATING THE ACTIVITY TIED TO THE KEYINDEX, which could lead to conflicts of priority
                            doneAfterSelectionOptions.push(
                                //Create one checkbox per other activity in this study
                                <input type="checkbox" key={keyCopy + keyIndex + j + activities[j]['class']  + '_afterCheck'} 
                                onChange={(event) => {

                                    //The form's event.target.value after checking the box is received as a string, convert to int.
                                    var target = parseInt(event.target.value);
                                    //Make a copy of the list of all activities that come after this one.
                                    var copyDoneAfter = doneAfterState;//[...activities[keyIndex]['done_after']];
                                    //Make a copy of the list of all activities that come before this one.
                                    var copyDoneBefore = doneBeforeState;

                                    //Get the index of the form's event.target.value (ie the activity the user just chacked the box for) and store it so that it can be spliced later.
                                    const indexAfterTarget = copyDoneAfter.indexOf(target);

                                    //Check if the form's event.target.value was found in the list of activities that the current activity must wait on. If it was found (ie indexAfterTarget > -1), then remove it from the list as it is now no longer checked. Else, add it to the list and do the same process to update the done before list.
                                    if(indexAfterTarget > -1) {
                                        copyDoneAfter.splice(indexAfterTarget, 1);
                                    } else {
                                        copyDoneAfter.push(target);
                                        //Check for this index in the opposing array
                                        const indexBeforeTarget = copyDoneBefore.indexOf(target);
                                        if(indexBeforeTarget > -1) {
                                            copyDoneBefore.splice(indexBeforeTarget, 1);
                                            onChangeNestedArray('done_before', copyDoneBefore);
                                        }
                                    }
                                    //Update the parent of the change.
                                    onChangeNestedArray('done_after', copyDoneAfter);
                                }}
                                name={keyCopy + keyIndex + j + 'afterLabel'}
                                checked={doneAfterState.includes(j)} value={activities[j]['id']}/>
                            );

                            doneBeforeSelectionOptions.push(
                                <input type="checkbox" key={keyCopy + keyIndex + j + activities[j]['class']  + '_beforeCheck'} 
                                onChange={(event) => {
                                    var target = parseInt(event.target.value);
                                    var copyDoneBefore = doneBeforeState;//[...activities[keyIndex]['done_before']];
                                    var copyDoneAfter = doneAfterState;

                                    const indexBeforeTarget = copyDoneBefore.indexOf(target);

                                    if(indexBeforeTarget > -1) {
                                        copyDoneBefore.splice(indexBeforeTarget, 1);
                                    } else {
                                        copyDoneBefore.push(target);
                                        //Check for this index in the opposing array
                                        const indexAfterTarget = copyDoneAfter.indexOf(target);
                                        if(indexAfterTarget > -1) {
                                            copyDoneAfter.splice(indexAfterTarget, 1);
                                            onChangeNestedArray('done_after', copyDoneAfter);
                                        }
                                    }
                                    onChangeNestedArray('done_before', copyDoneBefore);
                                }}
                                name={keyCopy + keyIndex + j + 'beforeLabel'}
                                checked={doneBeforeState.includes(j)} value={activities[j]['id']}/>
                            );

                            //Add a label for each check box
                            doneAfterSelectionOptions.push(
                                <label key={keyCopy + keyIndex + j + 'afterLabelKey'} htmlFor={keyCopy + keyIndex + j + 'afterLabel'}>{j + ': ' + activities[j]['class']}</label>
                            );
                            doneBeforeSelectionOptions.push(
                                <label key={keyCopy + keyIndex + j + 'beforeLabelKey'} htmlFor={keyCopy + keyIndex + j + 'beforeLabel'}>{j + ': ' + activities[j]['class']}</label>
                            );
                        }

                        //Add all three options with their lables to the UI
                        rows.push(
                            <Col key={keyCopy + keyIndex + "before_options"}>
                                <label key={keyCopy + keyIndex + "_label_before_options"} htmlFor="before_options">Only do after these activities:</label>
                                <div key={keyCopy + keyIndex + "before_div_holder"} name="before_options">{doneAfterSelectionOptions}</div>
                            </Col>
                        );
                        rows.push(
                            <Col key={keyCopy + keyIndex + "after_options"}>
                                <label key={keyCopy + keyIndex + "_label_after_options"} htmlFor="after_options">Activities that must wait on this activity:</label>
                                <div key={keyCopy + keyIndex + "after_div_holder"} name="after_options">{doneBeforeSelectionOptions}</div>
                            </Col>
                        );
                        break;
                    case "icon_picker":
                        var icon_options = [];
                        for(let i = 0; i < iconList.length; i++) {
                            icon_options.push({
                                id: iconList[i], 
                                backgroundImage: `url(` + iconList[i] + `)`, 
                                onClick: () => onChange(iconList[i], keyCopy)
                            })
                        }
                        rows.push(
                            <Col className="form-inline py-2">
                                <br></br>
                                <GridDropdown
                                    label="Icon"
                                    activeItem={values[keyCopy]}
                                    items={icon_options}
                                />
                                <Col>
                                    <img src={values[keyCopy]} width="50" height="50"/>
                                </Col>
                            </Col>
                        )
                        break;
                    default:
                        break;
                }
            }
        }
        return <div>{rows}</div>;
    }

    return (
        <>
            <div>
                {title}
                {templateToForm()}
            </div>
        </>
    );
}

export default FormBuilder;