import React from 'react'
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux'
import { STATUS } from '../../state/slices/userSlice.js'

import {
    Col,
    NavItem
} from "reactstrap";

export function MenuItems() {
    const authenticationStatus = useSelector((state) => state.user.status);
        if(authenticationStatus === STATUS.AUTHENTICATED)
            return (
                <Col>
                    <NavItem>
                        <Link to="/Manage-Studies">
                                <span className="nav-link-inner--text text-white ml-1">
                                    My Studies
                                </span>
                            </Link>
                    </NavItem>
                </Col>
            )
        else
            return (
                <></>
            )
}