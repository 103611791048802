import React from "react";

import {
    Button
  } from "reactstrap";
  
function JSONcsvBuilder({data}) {


    function downloadCSV() {
        let csvContent = "data:text/csv;charset=utf-8,";
        //A pre loop runs once to add the column labels
        var row = "";
        for (var index in data[0]) {
            row += '"' + index + '",';
        }
        
        //add a line break after each row
        csvContent += row + "\r\n";

        //1st loop is to extract each row
        for (var i = 0; i < data.length; i++) {
            row = "";
    
            //2nd loop will extract each column and convert it in string comma-seprated
            for (var index in data[i]) {
                row += '"' + data[i][index] + '",';
            }
        
            //add a line break after each row
            csvContent += row + "\r\n";
        }

        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "uplode_results.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    if(Object.keys(data).length === 0 && Object.getPrototypeOf(data) === Object.prototype) {
        return (
            <p>Loading</p>
        );
    } else {
        return (
            <Button onClick={downloadCSV}>
                <span className="nav-link-inner--text ml-1">
                    Download Results
                </span>
            </Button>
        );
    }
}

export default JSONcsvBuilder;