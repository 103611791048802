import React from "react";
import FormBuilder from "./FormBuilder";
import textDictionary from "assets/text_dictionary";

import {
    Button,
    Col,
    Row
  } from "reactstrap";
  
import { useState } from "react";

//This element needs access to all templates
//It will present a menu for users to sort through the templates they want
function ResourceElementBuilder({resourceElementTemplates, newElementCallBack, elementUpdateCallback, initialValues, index}) {

    const [elements, setElements] = useState(initialValues);
    const [listSelection, setListSelection] = useState('informational_link');

    //Pass in a newly requested element:
    //
    //The template of the item requested
    function addNewElement(template) {
        //Add the listSelection to the template for storage. Copy it since the parameter passed in is read only
        var nextTemplate = {};
        for (const key of Object.keys(template)) {
            nextTemplate[key] = '';
        }
        nextTemplate['type'] = listSelection;

        var nextElements = [...elements];
        nextElements.push(
            nextTemplate)
        setElements(nextElements);

        newElementCallBack(nextTemplate, index);
    }
    
    function renderElementOptions() {
        var dropDownMenu = [];
        var options = Object.keys(resourceElementTemplates);
        for(var i = 0; i < options.length; i++) {
            let value = options[i];
            dropDownMenu.push(<option value={value} key={dropDownMenu.length + 'listOptionAE'}>{textDictionary[value]}</option>)
        }
        return dropDownMenu;
    }

    function selectElement(selection) {
        setListSelection(selection.target.value);
    }

    function renderElements() {
        var renderedElements = [];
        var elementIndex = 0;

        for(const el of elements) {
            const currentIndex = elementIndex;
            renderedElements.push(
                <Col key={elements.length + 'ColRE' + currentIndex}>
                    <FormBuilder
                        title={textDictionary[el['type']]}
                        template={resourceElementTemplates[el['type']]}
                        custom_types={resourceElementTemplates['custom_types']}
                        callBack={(key, value) => {
                            elementUpdateCallback(key, value, el['type'], index, currentIndex)
                        }}
                        initialValues={elements[currentIndex]}
                        keyIndex={index + '_' + currentIndex + '_'}
                        key={elements.length + 'FormBuilderRE'}
                    />
                </Col>
            );
            renderedElements.push(<hr className="solid"></hr>);
            elementIndex++;
        }

        //renderedElements.push(<Col key="submit"><br></br><input type="submit" value="Create Study"/></Col>);
        return renderedElements;
    }

    return (
        <Col>
            <Row>
                <select
                    onChange={selectElement} 
                    name="SelectElementList"
                    id = "SelectElementList"
                >
                    {renderElementOptions()}
                </select>
                <Button
                    className="btn-neutral btn-icon"
                    color="default"
                    onClick={() => {
                            addNewElement(resourceElementTemplates[listSelection])
                        }
                    }
                >
                Add Resource Link
                </Button>
            </Row>
            <br></br>
            <Row>
                <Col>
                    {renderElements()}
                </Col>
            </Row>
        </Col>
    );
}

export default ResourceElementBuilder;