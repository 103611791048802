import React from "react";

import {
    Card
  } from "reactstrap";
  
function JSONTableBuilder({data}) {


    function getColumnHeaders() {
        var keys = Object.keys(data[0]);
        return keys.map((key, index)=>{
            return <th key={key}>{key.toUpperCase()}</th>
        })
    }

    const RenderRow = (props) =>{
        return props.keys.map((key, index)=>{
            return <td>{props.data[key]}</td>
        })
    }

    function getRowsData() {
        var keys = Object.keys(data[0]);
        return data.map((row, index)=>{
            return <tr key={index}><RenderRow key={index} data={row} keys={keys}/></tr>
        })
    }

    if(Object.keys(data).length === 0 && Object.getPrototypeOf(data) === Object.prototype) {
        return (
            <p>Loading</p>
        );
    } else if (data.length === 0) {
        return (
            <>
                <p>No Data</p>
            </>
        ) 
    } else {
        return (
            <>
            <style>{`
                    table {
                        font-family: arial, sans-serif;
                        border-collapse: collapse;
                        width: 100%;
                    }
                    
                    td {
                        border: 1px solid #dddddd;
                        text-align: left;
                        padding: 8px;
                    }

                    th {
                        position: sticky;
                        border: 1px solid #dddddd;
                        text-align: left;
                        padding: 8px;
                    }
                    
                    tr:nth-child(even) {
                        background-color: #dddddd;
                    }
                `}</style>
                <Card>
                    <div style={{height: 600, overflowX : 'auto', overflowY : 'auto', fontSize: '14px'}}>
                        <table>
                            <tr>
                                {getColumnHeaders()}
                            </tr>
                            <tbody>
                                {getRowsData()}
                            </tbody>
                        </table>
                    </div>
                </Card>
            </>
        );
    }
}

export default JSONTableBuilder;