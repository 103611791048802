import React from "react";
import { useStatePersist as useState } from 'use-state-persist';
import { useDispatch, useSelector } from 'react-redux';

import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import "../../assets/css/study-create-progress-bar.css"

import LandingNavbar from "components/Navbars/LandingNavbar.js";
import ActivtiyBuilder from "components/ActivityBuilder.js";
import ResourceBuilder from "components/ResourceBuilder.js";
import FormBuilder from "components/FormBuilder.js";
import StudyCreateReview from "components/StudyCreateReview";

import { createStudy } from '../../state/slices/userSlice.js'
import { fetchTemplates } from '../../state/slices/templatesSlice.js'

import {
    Card,
    Col,
    Row,
    Button
  } from "reactstrap";

function CreateStudyView() {
    const dispatch = useDispatch();
    let templates = useSelector((state) => state.templates.studyTemplate);
    const [pageNumber, setPageNumber] = useState('@pageNumber', 0);
    const [generalSettings, setGeneralSettings] = useState('@generalSettings', {});
    const [activities, setActivities] = useState('@activities', []);
    const [resources, setResources] = useState('@resources', []);

    React.useEffect(() => {
        async function fetchData() {
            await Promise.all([
                dispatch(fetchTemplates)
            ])
        }; 
        fetchData()
    }, []);

    //The following code snippet clears local storage on both tab closes and tab refreshes
    // window.onbeforeunload = () => {
    //     clearPersistedState();
    //   }

    //This function supports the 'cancel button'. It clears out all memory of the study configuration and renavigates the user to the general settings page so that they can start over
    async function clearPersistedState() {
        setGeneralSettings({});
        setActivities([]);
        setResources([]);
        changePage(0);
    }

    //Take the general settings, activities, and resources to make one JSON object ready for submission
    function compileStudy() {
        var nextStudy = {...generalSettings};
        generalSettings['surveys'] = [...activities];
        generalSettings['metadata'] = {};
        generalSettings['metadata']['resources'] = resources;
        generalSettings['metadata']['main_icon'] = generalSettings['main_icon'];
        generalSettings['metadata']['dynamic_timeline'] = generalSettings['dynamic_timeline'];
        generalSettings['metadata']['duration'] = generalSettings['duration'];
        generalSettings['public'] = false;
        generalSettings['type'] = 'Research Study';
        generalSettings['randomized_questions'] = false;
        return nextStudy;
    }

    function submitCreateStudyForm() {
        //console.log(compileStudy());
        // var temp = {"name":"THIS IS THE ONE","author":"7","org":"Senda, LLC","type":"Research","start_date":"now","end_date":"never","surveys":[{"name":"Consent","type":"Consent Form","questions":[{"pageNumber":"0","question":"Do you Consent?","questionNumber":"","text":"Tons of consent information blah blah blah.........","choices":"","type":"multiple_choice_single_select"}],"randomize_questions":false,"start_time":"Now","end_time":"Never","schedule":""},{"name":"Demographic","type":"Demographic Survey","questions":[{"pageNumber":"0","question":"This is the question","questionNumber":"","text":"This is the text","choices":"","type":"multiple_choice_multiple_select"},{"pageNumber":"1","question":"How old are you?","questionNumber":"","text":"How old are you?","type":"integer"}],"randomize_questions":false,"start_time":"Now","end_time":"Later","schedule":""}],"metadata":{},"public":false};
        //console.log(temp);
        //console.log(compileStudy());
        dispatch(createStudy(compileStudy()));
    }
    
    //Updates the state of the page selected.
    function changePage(page) {
        setPageNumber(page);
        //Scroll the window to the top
        window.scrollTo(0, 0)
    }

    //Returns the content for the page selected.
    function renderPage() {
        //If the page is still loading, simply show a loading box until the templates needed to render the form are present
        if(Object.keys(templates) < 1)
            return <Card className="shadow border-0 py-5 px-5" style={{width:"80%"}}>Loading</Card>
        else if(pageNumber === 0) 
            return <Col><Card className="shadow border-0 py-5 px-5">
                <h4>General Study Settings</h4>
                <FormBuilder
                    template={templates['study']}
                    callBack={(key, value) => {
                        generalSettings[key] = value;
                        setGeneralSettings(generalSettings);
                    }}
                    keyIndex={'_'}
                    initialValues={generalSettings}  
                />
            </Card>
            <br></br>
            <Row>
                <Col><span></span></Col>
                <Col xs="3">
                    <Button onClick={() => changePage(1)} color="secondary" block size="lg" type="button">Next: Activities</Button>
                </Col>
            </Row>
            </Col>
        else if (pageNumber === 1) 
            return <Col>{/*<Col md ="9">*/}
                <Row className="justify-content-center">
                    <ActivtiyBuilder
                        activityTemplate={templates['survey']}
                        activityElementTemplates={templates['survey_elements']}
                        newActivityCallBack={(activity) => {
                            activities.push(activity);
                            setActivities(activities);
                        }}
                        activityUpdateCallback={(key, value, index) => {
                            var nextActivity = {...activities[index]};
                            nextActivity[key] = value;
                            activities[index] = nextActivity;
                            setActivities(activities);
                        }}
                        newActivityElementCallback={(element, index) => {
                            var nextElements = [...activities[index]['questions']];
                            nextElements.push(element);
                            activities[index]['questions'] = nextElements;
                            setActivities(activities);
                        }}
                        activityElementUpdateCallback={(key, value, type, activityIndex, questionIndex) => {
                            //Check if the value is a nested object, and copy accordingly
                            if(Array.isArray(value)) {
                                activities[activityIndex]['questions'][questionIndex][key] = [...value];
                                activities[activityIndex]['questions'][questionIndex]['answerType'] = type;
                            } else {
                                activities[activityIndex]['questions'][questionIndex][key] = value;
                                activities[activityIndex]['questions'][questionIndex]['answerType'] = type;
                            }
                            setActivities(activities);
                        }}
                        initialValues={activities}/>
                    </Row>
                    <br></br>
                    <Row>
                        <Col xs="3">
                            <Button onClick={() => changePage(0)} color="secondary" block size="lg" type="button">Previous: General Settings</Button>
                        </Col>
                        <Col><span></span></Col>
                        <Col xs="3">
                            <Button onClick={() => changePage(2)} color="secondary" block size="lg" type="button">Next: Resources</Button>
                        </Col>
                    </Row>
                </Col>
        else if (pageNumber === 2)
            return <Col>
                <Row className="justify-content-center">
                    <ResourceBuilder
                        resourceTemplate={templates['resource']}
                        resourceElementTemplates={templates['resource_elements']}
                        newResourceCallBack={(resource) => {
                            resources.push(resource);
                            setResources(resources);
                        }}
                        resourceUpdateCallback={(key, value, index) => {
                            var nextResource = {...resources[index]};
                            nextResource[key] = value;
                            resources[index] = nextResource;
                            setResources(resources);
                        }}
                        newResourceElementCallback={(resource, index) => {
                            var nextLinks = [...resources[index]['links']];
                            nextLinks.push(resource);
                            resources[index]['links'] = nextLinks;
                            setResources(resources);
                        }}
                        resourceElementUpdateCallback={(key, value, type, resourceIndex, linksIndex) => {
                            resources[resourceIndex]['links'][linksIndex][key] = value;
                            setResources(resources);
                        }}
                        initialValues={resources}/>
                    </Row>
                    <br></br>
                    <Row>
                        <Col xs="3">
                            <Button onClick={() => changePage(1)} color="secondary" block size="lg" type="button">Previous: Activities</Button>
                        </Col>
                        <Col><span></span></Col>
                        <Col xs="3">
                            <Button onClick={() => changePage(3)} color="primary" block size="lg" type="button">Review and Publish</Button>
                        </Col>
                    </Row>
                </Col>
        else if (pageNumber === 3)
            return <Col>
                <StudyCreateReview studyMap={compileStudy()}></StudyCreateReview>
                <Row>
                    <Col xs = "3"><Button onClick={() => changePage(2)} color="secondary" block size="lg" type="button">Previous: Resources</Button></Col>
                    <Col><span></span></Col>
                    <Col xs = "2"><Button onClick={() => clearPersistedState()} color="secondary" block size="lg" type="button">Cancel</Button></Col>
                    <Col xs = "3"><Button onClick={() => {submitCreateStudyForm()}} color="primary" block size="lg" type="button">Create Study</Button></Col>
                </Row>
            </Col>
        else
            return <>Page Not Found</>
    }
    
    return (
        <>
        <header className="header-global">
            <LandingNavbar/>
        </header>
            <section className="section section-xl section-shaped pb-250">
            <div className="shape shape-style-1 shape-default">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            </div>
            {/* <Row>
                <Col xs="2.5">
                    <nav>
                        <div><Button onClick={() => changePage(0)} color="secondary" block size="lg" type="button">General Settings</Button></div>
                        <div><Button onClick={() => changePage(1)} color="secondary" block size="lg" type="button">Activities</Button></div>
                        <div><Button onClick={() => changePage(2)} color="secondary" block size="lg" type="button">Resources</Button></div>
                        <div><Button onClick={() => changePage(3)} color="primary" block size="lg" type="button">Review and Publish</Button></div>
                        <div><Button onClick={() => clearPersistedState()} color="secondary" block size="lg" type="button">Cancel</Button></div>
                    </nav>
                </Col>
                {renderPage()}
            </Row>
            */}
            <Col>
                <ProgressBar
                    percent={(pageNumber/3) * 100}
                    filledBackground="linear-gradient(to right, #00C8FF, #0074D9)">
                    <Step>
                        {({ accomplished, index }) => (
                        <div
                            className={`indexedStep ${accomplished ? "accomplished" : null}`}
                        >
                            {index + 1}    
                        </div>
                        )}
                    </Step>
                    <Step>
                        {({ accomplished, index }) => (
                        <div
                            className={`indexedStep ${accomplished ? "accomplished" : null}`}
                        >
                            {index + 1}
                        </div>
                        )}
                    </Step>
                    <Step>
                        {({ accomplished, index }) => (
                        <div
                            className={`indexedStep ${accomplished ? "accomplished" : null}`}
                        >
                            {index + 1}
                        </div>
                        )}
                    </Step>
                    <Step>
                        {({ accomplished, index }) => (
                        <div
                            className={`indexedStep ${accomplished ? "accomplished" : null}`}
                        >
                            {index + 1}
                        </div>
                        )}
                    </Step>
                </ProgressBar>
                <Row>
                    <Col sm className="py-2">
                        <p className="progressLabel" onClick={() => changePage(0)}>Study Settings</p>
                    </Col>
                    <Col sm className="py-2 text-center">
                        <p className="progressLabel" onClick={() => changePage(1)}>Study Activities</p>
                    </Col>
                    <Col sm className="py-2 text-center">
                        <p className="progressLabel" onClick={() => changePage(2)}>Study Resources</p>
                    </Col>
                    <Col sm className="py-2 d-flex justify-content-end">
                        <p className="progressLabel" onClick={() => changePage(3)}>Review and Publish</p>
                    </Col>
                </Row>
            </Col>
            {renderPage()}
        </section>
        </>
    );

}

export default CreateStudyView;