import React from "react";
import { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import FormBuilder from "./FormBuilder";
import ActivityElementBuilder from "./ActivityElementBuilder";
import textDictionary from "assets/text_dictionary";
import 'react-tabs/style/react-tabs.scss';


import {
    Button,
    Card,
    Col,
    Row
  } from "reactstrap";
  
//This element needs access to all templates
//It will present a menu for users to sort through the templates they want
function ActivtiyBuilder({activityTemplate, activityElementTemplates, newActivityCallBack, activityUpdateCallback, newActivityElementCallback, activityElementUpdateCallback, initialValues}) {

    console.log(initialValues);
    var listSelection = textDictionary[initialValues['class']];
    if (listSelection === undefined) listSelection = '---'

    const [elements, setElements] = useState(initialValues);
    const [tabHeaders, setTabHeaders] = useState([]); 
    const [tabIndex, setTabIndex] = useState(0);

    //Pass in a newly requested element:
    //
    //The template of the item requested
    function addNewActivity(template) {
        //Add the listSelection to the template for storage. Copy it since the parameter passed in is read only
        var nextElement = {};
        for (const key of Object.keys(template)) {
            nextElement[key] = '';
        }
        //Add a class to the listSelection that will inform general treatment for the activity
        nextElement['class'] = listSelection;
        //This id is used by the front end to allow the user to order and schedule elements. On the backend, it is translated to the database ID of the elements as they are created
        nextElement['id'] = elements.length;
        //Add an empty array of activities that this activity takes priority over
        nextElement['done_before'] = [];
        //Add an empty array of activities that this activity must wait for before presenting to the user
        nextElement['done_after'] = [];

        //Add a new element that will be populated in a tab
        var nextElements = [...elements];
        nextElements.push(nextElement);
        setElements(nextElements);

        //Create a new tab header
        var nextHeaders = tabHeaders;
        nextHeaders.push(listSelection);
        setTabHeaders(nextHeaders);

        //Provide the newest activity to this component's parent
        newActivityCallBack(nextElement);
    }

    function renderElements() {
        var renderedElements = [];
        var tabs = [];
        let selectionCopy = listSelection;
        //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!FIGURE OUT HOW TO GET THIS NOT TO NEED TWO LOOPS
        for(const el of elements) {
            tabs.push(
                <Tab key={tabs.length + tabIndex + 'Tab' + selectionCopy}>{(el['id'] + 1) + ': ' + textDictionary[el['class']]}</Tab>
            )
        }
        renderedElements.push(
            <TabList key={tabs.length + tabIndex + 'TabList' + selectionCopy}>
                {tabs}
            </TabList>
        );

        let keyIndex = 0;
        for(const el of elements) {
            //Create a new tab content item
            renderedElements.push(
                <TabPanel key={keyIndex + tabIndex + 'TabPanel' + selectionCopy}>
                    <Col key={tabs.length + 'Col1'} >
                        <h2 style={{color:"white"}}>{textDictionary[el['class']]} Settings</h2>
                        <br></br>
                        <Card className="shadow border-0 py-5 px-5" style={{width:"100%"}}>
                            <FormBuilder
                                template={activityTemplate}
                                custom_types={activityElementTemplates['custom_types']}
                                activities={elements}
                                callBack={(key, value) => {
                                    activityUpdateCallback(key, value, tabIndex)
                                }}
                                initialValues={elements[tabIndex]}
                                keyIndex={tabIndex}
                                key={tabs.length + 'ActivityFormBuilder'}
                            />
                        </Card>
                    </Col>
                    <br></br>
                    <Col key={tabs.length + 'Col2'} >
                        <h2 style={{color:"white"}}>{textDictionary[el['class']]} Content</h2>
                        <br></br>
                            <ActivityElementBuilder
                                activityElementTemplates={activityElementTemplates}
                                newActivityElementCallBack={newActivityElementCallback}
                                activityElementUpdateCallback={activityElementUpdateCallback}
                                initialValues={elements[tabIndex]['questions']}
                                key={tabs.length + 'ActivityElementBuilder'}
                                index={tabIndex}
                            />
                    </Col>
                </TabPanel>
            );
            keyIndex++;
        }
        return renderedElements;
    }

    return (
        <Col key='Main Col' >
            <Row className="justify-content-md-center" key='Main Row'>
                <Button
                    className="btn-neutral btn-icon"
                    color="default"
                    onClick={() => {
                            addNewActivity(activityTemplate)
                        }
                    }
                >
                Add Study Activity
                </Button>
            </Row>
            <br></br>
            <div style={{backgroundColor: 'rgba(0, 0, 0, 0)'}}>
                <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)} >
                    {renderElements()}
                </Tabs>
            </div>
            <hr></hr>
        </Col>
    );
}

export default ActivtiyBuilder;