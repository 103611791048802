import { configureStore } from '@reduxjs/toolkit'
import userReducer from './slices/userSlice.js'
import studyReducer from './slices/studySlice.js'
import templateReducer from './slices/templatesSlice.js';

export default configureStore({
  reducer: {
    user: userReducer,
    study: studyReducer,
    templates: templateReducer
  },
})