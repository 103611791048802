//The AuthenticatorForm manages the UI and state for logging in as well as creating an account.

import React from "react";
import { useState } from "react";
import { Amplify, Auth } from "aws-amplify";
import '@aws-amplify/ui/styles.css';
import awsconfig from "../../aws-exports";
import { useHistory } from "react-router-dom";

import {
  Button,
  Card,
  Col,
  Container,
  Row
} from "reactstrap";

Amplify.configure(awsconfig);

export function AuthenticatorForm() {


    ///STATE MANAGEMENT~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~


    //Boolean. This isSingingUp Boolean indicates to the UI if the user is currently interacting with the authentication
    //form or if they are interacting with the login form. When true, the signup form is displayed. When false,
    //the login form is displayed.
    const [isSigningUp, setIsSigningUp] = useState(false)

    //String. A part of the state of the signup form when isSigningUp is true, or a part of the state of the login form when isSigning up is false.
    const [username, setUsername] = useState(' ');
    //String. A part of the state of the signup form when isSigningUp is true, or a part of the state of the login form when isSigning up is false.
    const [password, setPassword] = useState(' ');

    //String. A part of the state of the signup form.
    const [passwordConfirm, setPasswordConfirm] = useState(' ');

    //String. The join code is currently meant to bar users from creating an account unless they have worked with sales
    //to create a contract first. It should be removed soon. Currently, it is a required field in the signup field.
    const [joinCode, setJoinCode] = useState(' ');

    //String. The auth message is displayed below the authentication form. Usually, it is an empty string.
    //In the event an error or alert, the setAuthMessage is called to display an appropriate notification
    //for the user below the authentication form.
    const [authMessage, setAuthMessage] = useState(' ');



    //Functions~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

    //history is used to navigate the user to the root page upon successful authentication. It interacts with the
    //site's router to push the root view onto the stack of views.
    const history = useHistory();

    //(Void) Future<Void>. Handles login requests. Pulls username and password associated with the authentication request from their corresponding state variables. Uses the Auth library to communicate the username and password with Cognito.
    const handleAuthRequest = async () => {
        //Attemtp to log in with the username and password via the Auth library. In the case of failure, set the auth message to notify the user that the username or password was incorrect.
        try {
            await Auth.signIn(username, password);
        } catch(e) {
            setAuthMessage("Username or Password Incorrect");
        }

        //If the Auth library is able to complete the authentication request without error, check if the Auth object has a valid session. If it does, redirect the user to the root page. If it does not, then alert the user by setting the authMessage to have an error code.
        Auth.currentSession().then( response => {
            if(response.isValid()) {
                history.push("/");
            } else {
                setAuthMessage("Account Error - Refresh and Log In Again")
            }
        }).catch(() => {
                setAuthMessage("Username or Password Incorrect");
        });
    }

    //(Void) Future<Void>. Handles sign up requests. Pulls username, password, password confirmation, and join code associated with the sign request from their corresponding state variables. Uses the Auth library to communicate the username, password, and join code with Cognito.
    const handleSignUpRequest = async () => {
        //First check that the user has successfully entered their desired password in both the password and password confirmation form fields. If they did not, stop the sign up process and alert the user.
        if(password === passwordConfirm) {
            //Use the Auth library to communicate the username, password, and joincode with Cognito. If there is an error, notify the user.
            try {
                await Auth.signUp(username, password, joinCode);
            } catch(e) {
                setAuthMessage("Error signing up.")
            }
            //Check if the user has a current session. If they do, navigate them to the root page. If they do not, send an alert to the user by setting the AuthMessage.
            Auth.currentSession().then( response => {
                if(response.isValid()) {
                    history.push("/");
                } else {
                    setAuthMessage("Username or Password Incorrect");
                }
            }).catch((e) => {
                    setAuthMessage(e);
            });
        } else {
            setAuthMessage("Passwords do not match")
        }
    }


    //USER INTERFACE~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

    return ( 
        <>
            <Row className="justify-content-center">
                <h4>Sign in to UpLode</h4>
            </Row>
            <Container>
                <Row className="justify-content-center">
                    <Card className="shadow border-0 py-3 col-lg-4 col-sm-6">
                        <Col>
                            <h6>Username</h6>
                            <Row>
                                <input type="text" id="username" name="username" className="form-control" onChange={(e) => setUsername(e.target.value)}/>
                            </Row>
                            <br/>
                            <h6>Password</h6>
                            <Row>
                                <input type="password" id="password" name="password" className="form-control" onChange={(e) => setPassword(e.target.value)}/>
                            </Row>
                            {
                                isSigningUp
                                ? <><br/><h6>Confirm Password</h6>
                                    <Row>
                                        <input type="password" id="password" name="password" className="form-control" onChange={(e) => setPasswordConfirm(e.target.value)}/>
                                    </Row></>
                                : null
                            }
                            {
                                isSigningUp
                                ? <><br/><h6>Access Code</h6>
                                    <Row>
                                        <input type="password" id="password" name="password" className="form-control" onChange={(e) => setJoinCode(e.target.value)}/>
                                    </Row></>
                                : null
                            }
                            <p>{authMessage}</p>
                            <br></br>
                            <Row className="justify-content-center">
                                <Button onClick={
                                    isSigningUp
                                        ? handleSignUpRequest
                                        : handleAuthRequest}>
                                <span className="nav-link-inner--text ml-1">
                                        {isSigningUp ? 'Create Account' : 'Login'}
                                    </span>
                                </Button>
                            </Row>
                        </Col>
                    </Card>
                </Row>
            </Container>
            <br></br>
            <Container>
                <Row className="justify-content-center">
                    <Card className="shadow border-0 py-3 col-lg-4 col-sm-6">
                        <Col>
                <Row className="justify-content-center">
                    {
                        isSigningUp
                        ? 'Already have an account?'
                        : 'New to UpLode?'
                    }
                </Row>
                <Row className="justify-content-center">
                    <Button onClick={() => {
                        isSigningUp
                            ? setIsSigningUp(false)
                            : setIsSigningUp(true);
                        //If the user changes between logging in and signing up, clear all form fields and notifications.
                        setAuthMessage('');
                        setPassword(' ');
                        setUsername(' ');
                    }}>
                        <span className="nav-link-inner--text ml-1">
                            {
                                isSigningUp
                                    ? 'Login'
                                    : 'Create Account'
                            }
                        </span>
                    </Button>
                </Row>
                </Col>
                </Card>
                </Row>
                </Container>
      </>
    );
}