import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import LandingNavbar from "components/Navbars/LandingNavbar.js";

import { fetchOwnedStudies } from '../../state/slices/userSlice.js'

import {
    Col,
    Row,
    Button
  } from "reactstrap";
import Card from 'reactstrap/lib/Card';
  

function ManageStudies() {
    const dispatch = useDispatch();
    let studies = useSelector((state) => state.user.ownedStudies);

    React.useEffect(() => {
        async function fetchData() {
        await Promise.all([
            dispatch(fetchOwnedStudies)
        ])}; fetchData()}, []);
    
    function placeHolder() {

    }

    function buildStudyTiles() {

        var cards = [];
        for(var i = 0; i < studies.length; i++) {
            cards.push(<Card style={{
                    margin: '20px',
                    padding: '10px'
                }}> 
                <Col>
                    <Row><h3>{(studies[i])['name']}</h3></Row>
                    <Row>Particpant Access Code: {(studies[i])['access_code']}</Row>
                    <Row className="justify-content-center" style={{
                        margin: '10px',
                    }}>
                    <Link to={{ pathname: "/edit-study", search: `study=${(studies[i])['id']}`}}>
                        <Button>
                            <span className="nav-link-inner--text ml-1">
                                Edit Study
                            </span>
                        </Button>
                    </Link>
                    <div style={{
                        margin: '20px',
                        padding: '10px'
                    }}></div>
                    <Link to={{ pathname: "/study-data", search: `study=${(studies[i])['id']}`}}>
                        <Button>
                            <span className="nav-link-inner--text ml-1">
                                View Data
                            </span>
                        </Button>
                    </Link>
                    </Row>
                </Col>
            </Card>)
        }
        return cards
        
    }

    return (
    <>
        <header className="header-global">
            <LandingNavbar/>
        </header>
            <section className="section section-xl section-shaped pb-250">
            <div className="shape shape-style-1 shape-default">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            </div>
            <Row className="justify-content-center">
                {buildStudyTiles()}
            </Row>
            <Row className="justify-content-center">
                <Link to={{ pathname: '/create-study'}}>
                    <Button>
                        <span className="nav-link-inner--text ml-1">
                            Create Study
                        </span>
                    </Button>
                </Link>
            </Row>
            </section>
        </>
    );
}

export default ManageStudies;