var textDictionary = {
    "icon": "Icon",
    "name": "Name",
    "org": "Organization",
    "type":"Schedule Type",
    "start_date":"Opens On",
    "end_date":"Closes On",
    "start_time":"Start Date",
    "end_time":"End Date",
    "main_icon":"Main Icon",
    "class": "Activity Class",
    "consent_form": "Consent Form",
    "participant_validation": "Participant Validation",
    "demographic_survey": "Demographic Survey",
    "baseline_survey": "Baseline Survey",
    "open_activity": "Ongoing Activity",
    "dynamic_activity": "Dynamic Activity",
    "scheduled_activity": "Scheduled Activity",
    "post_survey": "Post Survey",
    "dynamic_timeline":"Flexible Start Date",
    "duration": "Duration of Study (Days)",
    "schedule":"Schedule",
    "start_now":"Start Now",
    "randomize_questions": "Randomize Question Order",
    "pageNumber":"Page Number",
    "question":"Question",
    "questionNumber":"Question Number",
    "text":"Text",
    "choices":"Choices",
    "title":"Title",
    "informational_link":"Informational Link",
    "maps_link": "Maps Link",
    "email_link":"Email Link",
    "phone_number":"Phone Number",
    "custom_types":"Custom Types",
    "dynamic":"Dynamic Activity",
    "open":"Ongoing Activity",
    "scheduled":"Scheduled Activity",
    "single_response":"One Time Activity",
    "url": "Link",
    "informational_link": "Informational Link",
    "maps_link": "Maps Link",
    "email_link": "Email Link",
    "phone_number": "Phone Number",
    "neutralText": "Hidden? (true or false)",
    "highText": "Hours of step count history to retrieve on response (0-48)",
    "Multiple_Choice_Multiple_Select":"Multiple Choice Multiple Select",
    "Multiple_Choice_Single_Select":"Multiple Choice Single Select",
    "Integer":"Integer",
    "Short_Answer":"Short Answer",
    "Long_Answer":"Long Answer",
    "Likert_Scale":"Likert Scale",
    "Action_Instructions":"Action Instructions",
    "Youtube_Instructions":"Youtube Instructions",
    "Audio_Instructions":"Audio Instructions",
    "Timed_Activity":"Timed Activity",
    "Step_Counter":"Step Counter",
    "Pedometer": "Pedometer",
    "Time_Picker": "Time Picker",
    "Calendar_Picker": "Calendar Picker",
    "custom_types": "Custom Types"
}

export default textDictionary;