import React from "react";
import { Link } from "react-router-dom";
import { AuthenticatorForm } from "./authenticator.js"

import {
  Navbar,
  NavbarBrand,
  Container,
  Row,
  Col,
} from "reactstrap";

function Login () {
      return ( 
        <>
        <header className="header-global">
        <Navbar
          className="navbar-main navbar-transparent navbar-light headroom"
          expand="lg"
          id="navbar-main"
        >
          <Container>
            <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
                <Row>
                  <Col>
                    <img
                      alt="..."
                      src={require("assets/img/brand/profilepicturev2.jpg")}
                    />
                </Col>
                <Col>
                    <text className="mr-lg-5 lead text-white">Uplode Research</text>
                </Col>
                </Row>
            </NavbarBrand>
          </Container>
        </Navbar>
      </header>
        <section className="section section-xl section-shaped pb-250">
        <div className="shape shape-style-1 shape-default">
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
        </div>
        <AuthenticatorForm/>
      </section>
      </>
        );
      }

export default Login;