import axios from 'axios';
import { Auth } from 'aws-amplify'

var endpoint= 'https://hrc7vfla08.execute-api.us-east-1.amazonaws.com/production/mobile';

async function prepareHeaders() {
  var header = await Auth.currentSession().then( response => {
    if(response.isValid()) {
      return {
        header: response['idToken']['jwtToken']
      };
    } else {
        return {
          "header": ""
        };
    }
  });
  return header;
}

async function postCreateStudy(study) {
  var queryHeaders = await prepareHeaders();
  var response = await axios.post(endpoint, {request:"InsertStudy", study: study}, {headers: queryHeaders}).then((res)=>{  
    return res;
  }).catch((err)=>{
    console.log(err);
  });
  return response['data'];
}

async function getStudy() {
    axios.get(`https://jsonplaceholder.typicode.com/users`)
      .then(res => {
        const persons = res.data;
        this.setState({ persons });
      })
}

async function getOwnedStudies() {
  var queryHeaders = await prepareHeaders();
  var response = await axios.post(endpoint, {request:"GetOwnedStudies"}, {headers: queryHeaders}).then((res)=>{  
    return res['data'];
  }).catch((err)=>{
  });
  return response;
}

async function postFetchResponses(studyID) {
  var queryHeaders = await prepareHeaders();
  var response = await axios.post(endpoint, {request:"GetStudyResponses", study_id: studyID}, {headers: queryHeaders}).then((res)=>{  
    return res['data'];
  }).catch((err)=>{
  });
  return response;
}

async function getTemplates() {
  var queryHeaders = await prepareHeaders();
  var response = await axios.post(endpoint, {request:"GetTemplates"}, {headers: queryHeaders}).then((res)=>{
    return res['data'];
  }).catch((err)=>{
  });
  console.log(response);
  var stubResponse = {
    "study": {
        "name": "string",
        "main_icon": "icon_picker",
        "org": "string",
        "start_date": "calendar_picker",
        "end_date": "calendar_picker",
        "duration": "int",
        "dynamic_timeline": "boolean",
        "surveys": []
    },
    "survey": {
        "class": "class_options",
        "type": "schedule_options",
        "icon": "icon_picker",
        "name": "string",
        "questions": [],
        "start_time": "calendar_picker",
        "end_time": "calendar_picker",
        "schedule": "dynamic_schedule",
        "randomize_questions": "boolean",
        "start_now": "hidden",
        "ordering": "ordering"
    },
    "resource" : {
        "title": "string",
        "icon": "icon_picker",
        "links": [],
    },
    "resource_elements":
      {
        "informational_link" : {
          "url": "string",
          "text": "string"
        },
        "maps_link": {
          "url": "string",
          "text": "string"
        },
        "email_link": {
          "url": "string",
          "text": "string"
        },
        "phone_number": {
          "url": "string",
          "text": "string"
        },
        "custom_types": {
        }
    },
    "survey_elements": {
        "Multiple_Choice_Multiple_Select": {
            "pageNumber": "int",
            "question": "string",
            "questionNumber": "int",
            "text": "string",
            "choices": "string[]",
            "validation": "value/condition/param"
        },
        "Multiple_Choice_Single_Select": {
            "pageNumber": "int",
            "question": "string",
            "questionNumber": "int",
            "text": "string",
            "choices": "string[]",
            "validation": "value/condition/param"
        },
        "Integer": {
            "pageNumber": "int",
            "questionNumber": "int",
            "question": "string",
            "text": "string",
            "unit": "string",
            "validation": "value/condition/param"
        },
        "Short_Answer": {
            "pageNumber": "int",
            "questionNumber": "int",
            "value": "int", //Used as max length, is the same value as the one in the validation object
            "question": "string",
            "text": "string",
            "validation": "value/condition/param"
        },
        "Long_Answer": {
            "pageNumber": "int",
            "questionNumber": "int",
            "question": "string",
            "text": "string",
            "value": "int", //Used as max length, is the same value as the one in the validation object
            "validation": "value/condition/param"
        },
        "Likert_Scale": {
            "pageNumber": "int",
            "questionNumber": "int",
            "question": "string",
            "text": "string",
            "highText": "string",
            "lowText": "string",
            "neutralText": "string", //appears above the question widget as additional instruction
            "scaleMax": "int",
            "scaleMin": "int",
            "scaleDivision": "int",
            "validation": "value/condition/param"
        },
        "Action_Instructions": {
            "pageNumber": "int",
            "questionNumber": "int",
            "instructions": "instructions",
            "text": "string",
            "validation": "value/condition/param"
        },
        "Youtube_Instructions": {
            "pageNumber": "int",
            "questionNumber": "int",
            "text": "string",
            "question": "string",
            "validation": "value/condition/param"
        },
        "Audio_Instructions": {
            "pageNumber": "int",
            "questionNumber": "int",
            "text": "string",
            "question": "string",
            "validation": "value/condition/param"
        },
        "Timed_Activity": {
            "unit": "int",
            "validation": "value/condition/param",
            "question": "string",
            "text": "string",
            "pageNumber": "int",
            "questionNumber": "int"
        },
        "Step_Counter": {
            "pageNumber": "int",
            "questionNumber": "int",
            "text": "string",
            "question": "string",
            "neutralText": "string", //"true" or "false" to hide things or not
            "highText": "int", //Currently used to get the number of hours in the past to query steps on
            "validation": "value/condition/param"
        },
        "Pedometer": {
            "pageNumber": "int",
            "questionNumber": "int",
            "text": "string",
            "question": "string",
            "neutralText": "String", //"true" or "false" to hide things or not
            "highText": "int", //Currently used to get the number of hours in the past to query steps on
            "validation": "value/condition/param"
        },
        "Time_Picker": {
            "pageNumber": "int",
            "questionNumber": "int",
            "text": "string",
            "question": "string",
            "validation": "value/condition/param"
        },
        "Calendar_Picker": {
            "pageNumber": "int",
            "questionNumber": "int",
            "text": "string",
            "question": "string",
            "validation": "value/condition/param"
        },
        //custom_types enable nesting of more complicated values that can still be digested by the front-end UI while simplifying validation
        "custom_types": {
          "instructions": {
              "icon": "icon_picker",
              "title": "string",
              "instruction": "string"
          },
          "value/condition/param":{
              "value": "int",
              "condition": "string",
              "param": "param_options"  
          },
          "schedule_options": [
              "dynamic",
              "open",
              "scheduled",
              "single_response"
          ],
          "class_options": [
            "consent_form",
            "participant_validation",
            "demographic_survey",
            "baseline_survey",
            "open_activity",
            "dynamic_activity",
            "scheduled_activity",
            "post_survey"
          ],
          "param_options": [
              "value",
              "length"
          ],
          "ordering": {
            "done_before": "int[]",
            "done_after": "int[]"
          }
      }
    }
  }
  return stubResponse;
}

export {
  postCreateStudy,
  getStudy,
  getOwnedStudies,
  postFetchResponses,
  getTemplates
}
