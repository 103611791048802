import { createSlice } from '@reduxjs/toolkit';
import { getStudy, postFetchResponses } from '../../api/api.js';
//import { createStudy } from '../../api/api.js';

const STATUS = {
  NOT_LOADED: 'Not_Loaded',
  LOADING: 'Loading',
  LOADED: 'Loaded'
}

export const slice = createSlice({
  name: 'study',
  initialState: {
    name: "",
    author: "",
    org: "",
    type: "",
    start_date: "",
    end_date: "",
    surveys: [],
    responses: {},
    status: STATUS.NOT_LOADED
  },
  reducers: {
    changeStatus: (state, action) => {
      state.status = action.payload.status;
    },
    setStudy: (state, action) => {
        state.name = action.payload.name;
        state.author = action.payload.author;
        state.org = action.payload.org;
        state.type = action.payload.type;
        state.start_date = action.payload.start_date;
        state.end_date = action.payload.end_date;
        state.surveys = action.payload.surveys;
        state.status = STATUS.LOADED;
    },
    setResponses: (state, action) => {
      state.responses = action.payload;
    }
  },
});

export const { changeStatus, setStudy, setResponses } = slice.actions;

export const fetchStudy = studyID => async dispatch => {
    dispatch(changeStatus(STATUS.LOADING));
    var study = await getStudy(studyID);
    dispatch(setStudy(study));
};

export const fetchResponses = studyID => async dispatch => {
    var responses = await postFetchResponses(studyID);
    dispatch(setResponses(responses))
}

export const selectStudyName = state => state.name;

export default slice.reducer;