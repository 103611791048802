import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';

import LandingNavbar from "components/Navbars/LandingNavbar.js";
import ActivtiyBuilder from "components/ActivityBuilder.js";
import FormBuilder from "components/FormBuilder.js";

import { createStudy } from '../../state/slices/userSlice.js'
import { fetchTemplates } from '../../state/slices/templatesSlice.js'

import {
    Col,
    Row,
    Button
  } from "reactstrap";
import { fetchStudy } from "state/slices/studySlice.js";

function EditStudyView(props) {
    const dispatch = useDispatch();
    let studyID = (props.location.search).split('=')[1];

    let study = useSelector((state) => state.study.name);
    let templates = useSelector((state) => state.templates.studyTemplate);

    const [pageNumber, setPageNumber] = useState(0);
    const [generalSettings] = useState({});
    const [activities] = useState([]);
    const [resources] = useState({});

    React.useEffect(() => {
        async function fetchData() {
            await Promise.all([
                dispatch(fetchStudy(studyID)),
                dispatch(fetchTemplates)
            ])
        }; 
        fetchData()
    }, []);

    //Take the general settings, activities, and resources to make one JSON object ready for submission
    function compileStudy() {
        var nextStudy = {...generalSettings};
        generalSettings['surveys'] = [...activities];
        generalSettings['metadata'] = resources;
        generalSettings['public'] = false;
        return nextStudy;
    }

    function submitCreateStudyForm() {
        console.log(compileStudy());
        dispatch(createStudy(compileStudy()));
    }
    
    //Updates the state of the page selected.
    function changePage(page) {
        setPageNumber(page);
    }

    //Returns the content for the page selected.
    function renderPage() {
        if(pageNumber === 0)
            return <FormBuilder
                        title='Study Settings'
                        template={templates['study']}
                        callBack={(key, value) => {
                            generalSettings[key] = value;
                        }}
                        keyIndex={'_'}
                        initialValues={generalSettings}  
                    />
        else if (pageNumber === 1)
            return <Col>{/*<Col md ="9">*/}
                <Row className="justify-content-center">
                    <ActivtiyBuilder
                        activityTemplate={templates['survey']}
                        activityElementTemplates={templates['survey-elements']}
                        newActivityCallBack={(activity) => {
                            activities.push(activity);
                        }}
                        activityUpdateCallback={(key, value, index) => {
                            var nextActivity = {...activities[index]};
                            nextActivity[key] = value;
                            activities[index] = nextActivity;
                        }}
                        newActivityElementCallback={(element, index) => {
                            var nextElement = [...activities[index]['questions']];
                            nextElement.push(element);
                            activities[index]['questions'] = nextElement;
                        }}
                        activityElementUpdateCallback={(key, value, type, activityIndex, questionIndex) => {
                            activities[activityIndex]['questions'][questionIndex][key] = value;
                            activities[activityIndex]['questions'][questionIndex]['type'] = type;
                        }}
                        initialValues={activities}/>
                    </Row>
                </Col>
        else if (pageNumber === 2)
            return <>page 2</>
        else if (pageNumber === 3)
            return <Col>
                {JSON.stringify(compileStudy())}
                <Button onClick={() => {submitCreateStudyForm()}}>Create Study</Button>
            </Col>
        else
            return <>Page Not Found</>
    }
    
    return (
        <>
        <header className="header-global">
            <LandingNavbar/>
        </header>
            <section className="section section-xl section-shaped pb-250">
            <div className="shape shape-style-1 shape-default">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            </div>
            <Row>
                <Col xs="3">
                    <nav>
                        {/*<span>&#10003;</span> for icons*/}
                        <div><Button onClick={() => changePage(0)} color="secondary" block size="lg" type="button">General Settings</Button></div>
                        <div><Button onClick={() => changePage(1)} color="secondary" block size="lg" type="button">Activities</Button></div>
                        <div><Button onClick={() => changePage(2)} color="secondary" block size="lg" type="button">Resources</Button></div>
                        <div><Button onClick={() => changePage(3)} color="primary" block size="lg" type="button">Review and Publish</Button></div>
                    </nav>
                </Col>
                {renderPage()}
            </Row>
        </section>
        </>
    );
}


export default EditStudyView;