import React from "react";
import { useState } from "react";

import {
    Card,
    Col,
    Table
  } from "reactstrap";

function StudyCreateReview({studyMap}) {

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    //The date to project survey activities from
    const [dateInspected, setDateInspected] = useState(yyyy + '-' + mm + '-' + dd);

    function buildGeneralSettings() {
        var dynamicTimeline;
        if(studyMap['metadata'] === undefined) dynamicTimeline = false;
        else dynamicTimeline = studyMap['metadata']['dynamic_timeline'];
        return <Col>
            <p>Name: {studyMap['name']}</p>
            <p>Opens On: {studyMap['start_date']}</p>
            <p>Closes On: {studyMap['end_date']}</p>
            <p>Flexible Start Date: {dynamicTimeline ? 'True':'False'}</p>
            <p>Length of Study: {studyMap['duration']} days</p>
        </Col>
    }

    function buildActivityHeaders() {
        var headers = [];
            headers.push(<th key={'Table Header Activity'}>Activity</th>)
        for(var i = 0; i < studyMap['duration']; i++) {
            var dateIncrementor = new Date(dateInspected);
            dateIncrementor.setDate(dateIncrementor.getDate() + i + 1);
            headers.push(<th key={'Table Header' + i}>Day {i+1} ({dateIncrementor.toDateString()})</th>);
        }
        return headers;
    }

    function buildActivityRows() {
        var tableRows = [];
        //First check that there is data to work with. Else, return an empty set of rows.
        if(studyMap['surveys'] === undefined) return tableRows;
        //If there is data to work with, iterate through it to develop a table of availabilites. The surveys act as rows, the day in the study acts as a columns.
        for(var i = 0; i < studyMap['surveys'].length; i++) {
            var rowData = [];
            rowData.push(<td key={'activty_name_td'}>{studyMap['surveys'][i]['name']}</td>);
            //As of 8/17/2022, there are four different types of activties: Done Once, Scheduled, Dynamic, and Ongoing.
            //Done Once activities should only be displayed as available the first day that the participant views them, provided that time occurs sometime between the start and end dates of the activity.
            //Scheduled activities should only be dispalyed as available on dates found in their array of scheduled dates.
            //Dynamic activities should only be displayed as available following the pattern described in their schedule object.
            //Ongoing/Open activities should always be displayed as available.
            var type = studyMap['surveys'][i]['type'];
            switch (type) {
                case 'single_response':
                    var waiting = true;
                    var startTime = new Date(studyMap['surveys'][i]['start_time']);
                    var endTime = new Date(studyMap['surveys'][i]['end_time']);
                    for(var j = 0; j < studyMap['duration']; j++) {
                        var dateIncrementor = new Date(dateInspected);
                        dateIncrementor.setDate(dateIncrementor.getDate() + j );
                        if(dateIncrementor >= startTime && dateIncrementor <= endTime && waiting) {
                            rowData.push(<td key={'dynamic_td' + i + j}>Available</td>);
                            waiting = false;
                        }
                        else rowData.push(<td key={'dynamic_td' + i + j}>X</td>);
                    }
                    break;
                case 'scheduled':
                    var startTime = new Date(studyMap['surveys'][i]['start_time']);
                    var endTime = new Date(studyMap['surveys'][i]['end_time']);
                    for(var j = 0; j < studyMap['duration']; j++) {
                        var dateIncrementor = new Date(dateInspected);
                        dateIncrementor.setDate(dateIncrementor.getDate() + j);
                        //Check if the schedule array holds a date equal to the incrementor
                        var scheduled = false;
                        for(var k = 0; k < studyMap['surveys'][i]['schedule'].length; k++) {
                            var scheduledDate = new Date(studyMap['surveys'][i]['schedule'][k]);
                            if(
                                scheduledDate.getDate() === dateIncrementor.getDate()
                                && scheduledDate.getMonth() === dateIncrementor.getMonth()
                                && scheduledDate.getFullYear() === dateIncrementor.getFullYear()
                            ) scheduled = true;
                        }
                        if(dateIncrementor >= startTime && dateIncrementor <= endTime && scheduled) {
                            rowData.push(<td key={'scheduled_td' + i + j}>Available</td>);
                            waiting = false;
                        }
                        else rowData.push(<td key={'scheduled_td' + i + j}>X</td>);
                    }
                    break;
                case 'dynamic':
                    var startTime = new Date(studyMap['surveys'][i]['start_time']);
                    var endTime = new Date(studyMap['surveys'][i]['end_time']);
                    var nextIndexAvailable = 0;
                    nextIndexAvailable += parseInt(studyMap['surveys'][i]['schedule']['Delay']);
                    for(var j = 0; j < studyMap['duration']; j++) {
                        var dateIncrementor = new Date(dateInspected);
                        dateIncrementor.setDate(dateIncrementor.getDate() + j);
                        if(dateIncrementor >= startTime && dateIncrementor <= endTime && j === nextIndexAvailable) {
                            rowData.push(<td key={'dynamic' + i + j}>Available</td>);
                            nextIndexAvailable += parseInt(studyMap['surveys'][i]['schedule']['Frequency']);
                        }
                        else rowData.push(<td key={'dynamic' + i + j}>X</td>);
                    }
                    break;
                case 'open':
                    var startTime = new Date(studyMap['surveys'][i]['start_time']);
                    var endTime = new Date(studyMap['surveys'][i]['end_time']);
                    for(var j = 0; j < studyMap['duration']; j++) {
                        var dateIncrementor = new Date(dateInspected);
                        dateIncrementor.setDate(dateIncrementor.getDate() + j);
                        if(dateIncrementor >= startTime && dateIncrementor <= endTime) {
                            rowData.push(<td key={'ongoing_td' + i + j}>Available</td>);
                            waiting = false;
                        }
                        else rowData.push(<td key={'ongoing_td' + i + j}>X</td>);
                    }
                    break;
                default:
                    break;
            }
            tableRows.push(<tr key={'activity_tr' + i}>{rowData}</tr>);
        }
        return tableRows;
    }

    function buildResourceRows() {
        var tableRows = [];
        if(studyMap['metadata'] === undefined) return [];
        for(var i = 0; i < studyMap['metadata']['resources'].length; i++) {
            for(var j = 0; j < studyMap['metadata']['resources'][i]['links'].length; j++) {
                var rowData = [];
                rowData.push(<td key={'resource_td_group' + i + j}>{studyMap['metadata']['resources'][i]['title']}</td>);
                rowData.push(<td key={'resource_td_text' + i + j}>{studyMap['metadata']['resources'][i]['links'][j]['text']}</td>);
                rowData.push(<td key={'resource_td_type' + i + j}>{studyMap['metadata']['resources'][i]['links'][j]['type']}</td>);
                rowData.push(<td key={'resource_td_url' + i + j}>{studyMap['metadata']['resources'][i]['links'][j]['url']}</td>);
                tableRows.push(<tr key={'resource_tr' + i + j}>{rowData}</tr>);
            }
        }
        return tableRows;
    }

    return (
        <>
        <style>{`
            table {
                font-family: arial, sans-serif;
                border-collapse: collapse;
                width: 100%;
            },

            th {
                width: 400px
            }
        `}</style>
        <Card>
            <h4>Study Settings</h4>
            {buildGeneralSettings()}
        </Card>
        <br></br>
        <Card>
            <h4>Study Timeline</h4>
            <Col>
                Participant Start Date
                <input type="date" name={'dateSelector'} key={'dateSelector'} 
                onChange={(event) => {
                    setDateInspected(event.target.value);
                }} 
                value={dateInspected} 
                className="form-control"/>
            </Col>
            <br></br>
            <div style={{overflowX : 'auto', overflowY : 'auto', fontSize: '14px'}}>
                <Table hover>
                    <thead>
                        <tr>
                            {buildActivityHeaders()}
                        </tr>
                    </thead>
                    <tbody>
                            {buildActivityRows()}
                    </tbody>
                </Table>
            </div>
        </Card>

        <br></br>

        <Card>
            <h4>Study Resources</h4>
            <Table>
                <thead>
                    <tr>
                        <th>Group</th>
                        <th>Title</th>
                        <th>Type</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                    {buildResourceRows()}
                </tbody>
            </Table>
        </Card>
        <br></br>
        </>
    )
}

export default StudyCreateReview;