import React from "react";
import { useDispatch, useSelector } from 'react-redux';

import LandingNavbar from "components/Navbars/LandingNavbar.js";

import { fetchResponses } from '../../state/slices/studySlice.js';

import JSONTableBuilder from 'components/JSONTableBuilder.js';
import JSONcsvBuilder from 'components/JSONcsvBuilder.js';

import {
    Col,
    Row
  } from "reactstrap";
  

function StudyData(props) {
    const dispatch = useDispatch();
    let studyID = (props.location.search).split('=')[1];
    let responses = useSelector((state) => state.study.responses);

    React.useEffect(() => {
        async function fetchData() {
        await Promise.all([
            dispatch(fetchResponses(studyID))
        ])}; fetchData()}, []);

    return (
    <>
      <header className="header-global">
        <LandingNavbar/>
      </header>
        <section className="section section-xl section-shaped pb-250">
        <div className="shape shape-style-1 shape-default">
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
        </div>
        <Row className="justify-content-center">
                <JSONcsvBuilder 
                    data={responses}
                />
        </Row>
        <br></br>
        <Row className="justify-content-center">
            <Col md ="10">
                    <JSONTableBuilder 
                        data={responses}
                    />
            </Col>
        </Row>
         </section>
        </>
    );
}

export default StudyData;