import { createSlice } from '@reduxjs/toolkit';
import { postCreateStudy, getOwnedStudies } from '../../api/api.js'

export const STATUS = {
    NOT_AUTHENTICATED: 'Not_Authenticated',
    AUTHENTICATING: 'Authenticating',
    AUTHENTICATED: 'Authenticated'
  }

export const slice = createSlice({
  name: 'user',
  initialState: {
    accountEmail: "",
    ownedStudies: [],
    status: STATUS.NOT_AUTHENTICATED
  },
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setOwnedStudies: (state, action) => {
      state.ownedStudies = action.payload;
    }
  },
});

export const { setStatus, setOwnedStudies } = slice.actions;

export const createStudy = response => async dispatch => {
  var nextStudy = await postCreateStudy(response);
}

export const fetchOwnedStudies = async dispatch => {
  var ownedStudies = await getOwnedStudies();
  dispatch(setOwnedStudies(ownedStudies));
};

export const authStatus = state => state.user.status;

export const ownedStudies = state => state.user.ownedStudies;

export default slice.reducer;