import { createSlice } from '@reduxjs/toolkit';
import { getTemplates } from '../../api/api.js'

export const STATUS = {
    NOT_LOADED: 'NOT_LOADED',
    LOADING_STUDY: 'LOADING_STUDY',
    LOADED_STUDY: 'LOADED_STUDY',
    LOADING_SURVEY: 'LOADING_SURVEY',
    LOADED_SURVEY: 'LOADED_SURVEY',
    LOADING_SURVEY_ELEMENTS: 'LOADING_SURVEY_ELEMENTS',
    LOADED_SURVEY_ELEMENTS: 'LOADED_SURVEY_ELEMENTS'
}

export const slice = createSlice({
  name: 'templates',
  initialState: {
    status: STATUS.NOT_LOADED,
    studyTemplate: {},
    surveyTemplate: {},
    surveyElementsTemplate: {}
  },
  reducers: {
    setStatus: (state, action) => {
        state.status = action.payload;
    },
    setStudyTemplate: (state, action) => {
        state.studyTemplate = action.payload;
    },
    setSurveyTemplate: (state, action) => {
        state.surveyTemplate = action.payload;
    },
    setSurveyElementsTemplate: (state, action) => {
        state.surveyElementsTemplate = action.payload;
    }
  },
});

export const { setStatus, setStudyTemplate, setSurveyTemplate, setSurveyElementsTemplate } = slice.actions;

export const fetchTemplates = async dispatch => {
    var templates = await getTemplates();
    dispatch(setStudyTemplate(templates));
};

export const templatesStatues = state => state.templates.status;
export const studyTemplate = state => state.templates.studyTemplate;
export const surveyTemplate = state => state.templates.surveyTemplate;
export const surveyElementsTemplate = state => state.templates.surveyElementsTemplate;

export default slice.reducer;