var iconList = [
    "https://sendallc-uplode-icons-us-east-1.s3.amazonaws.com/Brain-Black.png",
    "https://sendallc-uplode-icons-us-east-1.s3.amazonaws.com/Breath-Blue.png",
    "https://sendallc-uplode-icons-us-east-1.s3.amazonaws.com/Breath-Light-Blue.png",
    "https://sendallc-uplode-icons-us-east-1.s3.amazonaws.com/Calendar-Yellow.png",
    "https://sendallc-uplode-icons-us-east-1.s3.amazonaws.com/Chronic_Illness_Information.png",
    "https://sendallc-uplode-icons-us-east-1.s3.amazonaws.com/Eye-Grey.png",
    "https://sendallc-uplode-icons-us-east-1.s3.amazonaws.com/FIGURE-8.png",
    "https://sendallc-uplode-icons-us-east-1.s3.amazonaws.com/Financial-Information.png",
    "https://sendallc-uplode-icons-us-east-1.s3.amazonaws.com/Heart-Red.png",
    "https://sendallc-uplode-icons-us-east-1.s3.amazonaws.com/Journal.png",
    "https://sendallc-uplode-icons-us-east-1.s3.amazonaws.com/Journaling.png",
    "https://sendallc-uplode-icons-us-east-1.s3.amazonaws.com/Lightbulb-Yellow.png",
    "https://sendallc-uplode-icons-us-east-1.s3.amazonaws.com/Loving_Kindness.png",
    "https://sendallc-uplode-icons-us-east-1.s3.amazonaws.com/Millenial-Caregiver-U-of-U.png",
    "https://sendallc-uplode-icons-us-east-1.s3.amazonaws.com/Mindful_Breathing.png",
    "https://sendallc-uplode-icons-us-east-1.s3.amazonaws.com/Mindfulness.png",
    "https://sendallc-uplode-icons-us-east-1.s3.amazonaws.com/Mountain-Grey.png",
    "https://sendallc-uplode-icons-us-east-1.s3.amazonaws.com/Resources.png",
    "https://sendallc-uplode-icons-us-east-1.s3.amazonaws.com/Seasons-Black.png",
    "https://sendallc-uplode-icons-us-east-1.s3.amazonaws.com/Sitting-Blue.png",
    "https://sendallc-uplode-icons-us-east-1.s3.amazonaws.com/Sitting-Ground-Grey.png",
    "https://sendallc-uplode-icons-us-east-1.s3.amazonaws.com/STOP-icon.png",
    "https://sendallc-uplode-icons-us-east-1.s3.amazonaws.com/STOP.png",
    "https://sendallc-uplode-icons-us-east-1.s3.amazonaws.com/Sun-Black.png",
    "https://sendallc-uplode-icons-us-east-1.s3.amazonaws.com/Sun-Orange.png",
    "https://sendallc-uplode-icons-us-east-1.s3.amazonaws.com/Team-Light-Blue.png",
    "https://sendallc-uplode-icons-us-east-1.s3.amazonaws.com/Team-Orange.png",
    "https://sendallc-uplode-icons-us-east-1.s3.amazonaws.com/Thoughts-Orange.png",
    "https://sendallc-uplode-icons-us-east-1.s3.amazonaws.com/WALK-BLUE.png",
]

export default iconList;