import React from 'react'
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux'
import { STATUS } from '../../state/slices/userSlice.js'
import { Auth } from 'aws-amplify'

import {
    Button,
    NavItem
  } from "reactstrap";

export function AuthButton() {
    const authenticationStatus = useSelector((state) => state.user.status);
        if(authenticationStatus === STATUS.AUTHENTICATED)
            return (
                    <NavItem>
                        <Link to="/Login">
                            <Button
                                className="btn-neutral btn-icon"
                                color="default"
                                onClick={() => {
                                    Auth.signOut()
                                  }
                                }
                            >
                            <span className="nav-link-inner--text ml-1">
                                Log Out
                            </span>
                            </Button>
                        </Link>
                    </NavItem>
            )
        else if (authenticationStatus === STATUS.AUTHENTICATING)
            return (
                <NavItem>
                    <Link to="/Login">
                        <Button
                            className="btn-neutral btn-icon"
                            color="default"
                        >
                        <span className="nav-link-inner--text ml-1">
                            { authenticationStatus }
                        </span>
                        </Button>
                    </Link>
                </NavItem>
            )
        else if (authenticationStatus === STATUS.NOT_AUTHENTICATED)
            return (
                <NavItem>
                    <Link to="/Login">
                        <Button
                            className="btn-neutral btn-icon"
                            color="default"
                        >
                        <span className="nav-link-inner--text ml-1">
                            Log In
                        </span>
                        </Button>
                    </Link>
                </NavItem>
            )
        else
                return (
                    <></>
                )
}