/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:f3864131-52e6-420e-8dda-25f15851adbd",//"us-east-1:258944437592:userpool/us-east-1_uDoa9QOdx",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_dmEvm7FqE",
    "aws_user_pools_web_client_id": "56l6tu3a5ecdmpcv1vuhe4lngn",
    //"authenticationFlowType": "CUSTOM_AUTH"
};


export default awsmobile;