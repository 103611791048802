import React from "react";
import LandingNavbar from "components/Navbars/LandingNavbar.js";
import '../../assets/css/user-documents.css';

function Privacy () {

    return ( 
        <>
            <header className="header-global">
                <LandingNavbar/>
            </header>
            <section className="section section-xl section-shaped pb-0">
                <div className="shape shape-style-1 shape-default">
                </div>
            </section>
            <div className="row-container">
                <iframe src="https://app.termly.io/document/privacy-policy/89c9d972-ff04-4c21-acef-0a4c2bb42b1b"></iframe>
            </div>
        </>
    );
}

export default Privacy;