import React from "react";
import LandingNavbar from "components/Navbars/LandingNavbar.js";
import '../../assets/css/user-documents.css';

function TermsAndConditions () {
      return ( 
        <>
        <header className="header-global">
            <LandingNavbar/>
        </header>
        <section className="section section-xl section-shaped pb-0">
            <div className="shape shape-style-1 shape-default">
            </div>
        </section>
        <div className="row-container">
            <iframe title="terms-and-conditions-document" src="https://app.termly.io/document/terms-of-use-for-saas/1d9569af-3aca-417b-b57e-5c9e58c84cdb"></iframe>
            </div>
        </>
        );
      }
export default TermsAndConditions;