import React from "react";
import { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import FormBuilder from "./FormBuilder";
import ResourceElementBuilder from "./ResourceElementBuilder";
import 'react-tabs/style/react-tabs.scss';


import {
    Button,
    Card,
    Col,
    Row
  } from "reactstrap";
  
//This element needs access to all templates
//It will present a menu for users to sort through the templates they want
function ResourceBuilder({resourceTemplate, resourceElementTemplates, newResourceCallBack, resourceUpdateCallback, newResourceElementCallback, resourceElementUpdateCallback, initialValues}) {

    const [resources, setResources] = useState(initialValues);
    const [tabHeaders, setTabHeaders] = useState([]); 
    const [tabIndex, setTabIndex] = useState(0);

    //Pass in a newly requested resource:
    //
    //The template of the item requested
    function addNewResource(template) {
        //Add the listSelection to the template for storage. Copy it since the parameter passed in is read only
        var nextElement = {};
        for (const key of Object.keys(template)) {
            nextElement[key] = '';
        }

        //Add a new element that will be populated in a tab
        var nextElements = [...resources];
        nextElements.push(nextElement);
        setResources(nextElements);

        //Create a new tab header
        var nextHeaders = tabHeaders;
        nextHeaders.push('Resource ' + resources.length);
        setTabHeaders(nextHeaders);

        //Provide the newest resource to this component's parent
        newResourceCallBack(nextElement);
    }

    function renderResources() {
        var renderedElements = [];
        var tabs = [];
        var index = 0;
        //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!FIGURE OUT HOW TO GET THIS NOT TO NEED TWO LOOPS
        for(const res of resources) {
            tabs.push(
                <Tab key={tabs.length + index + 'Resource_Tab' + resources.length}>{'Resource Group ' + index}</Tab>
            )
            index++;
        }
        renderedElements.push(
            <TabList key={tabs.length + index + 'Resource_Tab_List' + resources.length}>
                {tabs}
            </TabList>
        );

        let keyIndex = 0;
        for(const el of resources) {
            //Create a new tab content item
            renderedElements.push(
                <TabPanel key={keyIndex + tabIndex + 'Resource_Tab_Panel' + resources.length}>
                    <Card className="shadow border-0 py-5 px-5" style={{width:"100%"}}>
                        <Col key={tabs.length + 'Col'} >
                            <h4>{'Resource Group ' + keyIndex} Settings</h4>
                            <FormBuilder
                                template={resourceTemplate}
                                custom_types={resourceElementTemplates['custom_types']}
                                activities={resources}
                                callBack={(key, value) => {
                                    resourceUpdateCallback(key, value, tabIndex)
                                }}
                                initialValues={resources[tabIndex]}
                                keyIndex={tabIndex}
                                key={tabs.length + 'ResourceFormBuilder'}
                            />
                            <br></br>
                            <hr></hr>
                            <h4>{'Resource Group ' + keyIndex} Elements</h4>
                            <br></br>
                            <Row key={tabs.length + 'Row2'}>
                                <ResourceElementBuilder
                                    resourceElementTemplates={resourceElementTemplates}
                                    newElementCallBack={newResourceElementCallback}
                                    elementUpdateCallback={resourceElementUpdateCallback}
                                    initialValues={resources[tabIndex]['links']}
                                    key={tabs.length + 'ResourceElementBuilder'}
                                    index={tabIndex}
                                />
                            </Row>
                        </Col>
                    </Card>
                </TabPanel>
            );
            keyIndex++;
        }
        return renderedElements;
    }

    return (
        <Col key='Main Col' >
            <Row className="justify-content-md-center" key='Main Row'>
                <Button
                    className="btn-neutral btn-icon"
                    color="default"
                    onClick={() => {
                            addNewResource(resourceTemplate)
                        }
                    }
                >
                Add Resource Group
                </Button>
            </Row>
            <br></br>
            <div style={{backgroundColor: 'rgba(0, 0, 0, 0)'}}>
                <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)} >
                    {renderResources()}
                </Tabs>
            </div>
        </Col>
    );
}

export default ResourceBuilder;