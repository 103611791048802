/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/uplode
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/uplode/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect, useHistory } from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/uplode.scss?v1.1.0";

import { useEffect, useState } from "react"; 
import store from './state/store.js'
import AmplifyBridge from './state/AmplifyBridge.js'
import { Provider } from 'react-redux'
import { Auth } from 'aws-amplify'

import Index from "views/Index.js";
import Login from "views/account_management/login.js"

import Privacy from "views/user_documents/privacy.js";
import TermsAndConditions from "views/user_documents/terms_and_conditions.js"

import ManageStudies from "views/studies/manage_studies.js";
import CreateStudyView from "views/studies/create_study.js";
import EditStudyView from "views/studies/edit_study.js";
import StudyData from "views/studies/study_data.js";


import { useDispatch } from 'react-redux';
import {
  setStatus,
  STATUS
} from './state/slices/userSlice.js'

new AmplifyBridge(store);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        <Route
          path="/login"
          render={props => <Login {...props} />}
        />
        <Route
          path="/privacy"
          render={props => <Privacy {...props} />}
        />
        <Route
          path="/terms-and-conditions"
          render={props => <TermsAndConditions {...props} />}
        />
        <PrivateRoute
          path="/manage-studies"
          render={props => <ManageStudies {...props} />}
        />
        <PrivateRoute
          path="/create-study"
          render={props => <CreateStudyView {...props} />}
        />
        <PrivateRoute
          path="/edit-study"
          render={props => <EditStudyView {...props}/>}
        />
        <PrivateRoute
          path="/study-data"
          render={props => <StudyData {...props}/>}
        />
        <Route path="/" render={props => <Index {...props} />} />
        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

function PrivateRoute({ children, ...rest }) {
  
  const dispatch = useDispatch();

  const history = useHistory();

  const [auth, setAuth] = useState(false);

  const isAuthenticated = () => {

      setAuth(false);

      Auth.currentSession().then( response => {
          if(response.isValid()) {
            //At this point, response holds the jwt
            console.log('now it is authenticated')
            dispatch(setStatus(STATUS.AUTHENTICATED))
              setAuth(true);
          } else {
              console.log('not valid')
              dispatch(setStatus(STATUS.NOT_AUTHENTICATED))
              redirectToLogin();
          }
      }).catch(() => {
          redirectToLogin();
      });
  }

  const redirectToLogin = () => {
      history.push('/login');
  }

  useEffect(() => {
      isAuthenticated();
  }, []);

  return (
      <Route {...rest}>
          { auth ? children : null }
      </Route>
  )
}
