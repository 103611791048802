import { Auth, Hub } from "aws-amplify";
//import { useDispatch } from 'react-redux';
import { setStatus, STATUS } from '../state/slices/userSlice.js'

export default class AmplifyBridge {
    constructor(store) {
      this.store = store;
  
      this.onHubCapsule = this.onHubCapsule.bind(this);
      Hub.listen('auth', this, 'AmplifyBridge'); // Add this component as a listener of auth events.
  
      this.checkUser(); // first check
    }
  
    onHubCapsule(capsule) {
      this.checkUser(); // triggered every time user sign in / out
    }
  
    checkUser() {
      Auth.currentAuthenticatedUser()
        .then(user => {
          this.store.dispatch(setStatus(STATUS.AUTHENTICATED));
        })
        .catch(err => {
          this.store.dispatch(setStatus(STATUS.NOT_AUTHENTICATED));
        });
    }
  
    // loadProfile() {
    //   Auth.userAttributes(user)
    //     .then(data => {
    //       const profile = this.translateAttributes(data);
    //       this.store.dispatch(updateProfile(profile));
    //     })
    //     .catch(err => this.store.dispatch(deleteProfile(err)));
    // }
  }