/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/uplode
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/uplode/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
import { AuthButton } from "./AuthButton.js"
import { MenuItems } from "./MenuItems.js";
// reactstrap components
import {
  Nav,
  NavbarBrand,
  Navbar,
  Container,
  Row,
  Col
} from "reactstrap";
import { Menu } from "@aws-amplify/ui-react";

class LandingNavbar extends React.Component {
  componentDidMount() {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
  }
  state = {
    collapseClasses: "",
    collapseOpen: false
  };

  onExiting = () => {
    this.setState({
      collapseClasses: "collapsing-out"
    });
  };

  onExited = () => {
    this.setState({
      collapseClasses: ""
    });
  };

  render() {
    return (
      <>
        <header className="header-global">
          <Navbar
            className="navbar-main navbar-transparent navbar-light headroom"
            expand="lg"
            id="navbar-main"
          >
            <Container>
              <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
                  <Row>
                    <Col>
                      <img
                        alt="..."
                        src={require("assets/img/brand/profilepicturev2.jpg")}
                      />
                  </Col>
                  <Col>
                      <text className="mr-lg-5 lead text-white">Uplode Research</text>
                  </Col>
                  </Row>
              </NavbarBrand>
              <Nav className="align-items-lg-center ml-lg-auto" navbar>
                  <MenuItems/>
                  <AuthButton/>
                </Nav>
            </Container>
          </Navbar>
        </header>
      </>
    );
  }
}

export default LandingNavbar;
