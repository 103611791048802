import React from "react";
import FormBuilder from "./FormBuilder";
import textDictionary from "assets/text_dictionary";

import {
    Button,
    Card,
    Col,
    Row
  } from "reactstrap";
  
import { useState } from "react";

//This element needs access to all templates
//It will present a menu for users to sort through the templates they want
function ActivityElementBuilder({activityElementTemplates, newActivityElementCallBack, activityElementUpdateCallback, initialValues, index}) {

    const [elements, setElements] = useState(initialValues);
    const [listSelection, setListSelection] = useState('Multiple_Choice_Multiple_Select');

    //Pass in a newly requested element:
    //
    //The template of the item requested
    function addNewElement(template) {
        //Add the listSelection to the template for storage. Copy it since the parameter passed in is read only
        var nextTemplate = {};
        for (const key of Object.keys(template)) {
            nextTemplate[key] = '';
        }
        nextTemplate['answerType'] = listSelection;

        var nextElements = [...elements];
        nextElements.push(nextTemplate);
        setElements(nextElements);

        newActivityElementCallBack(nextTemplate, index);
    }
    
    function renderElementOptions() {
        var dropDownMenu = [];
        var options = Object.keys(activityElementTemplates);
        for(var i = 0; i < options.length; i++) {
            let value = options[i];
            dropDownMenu.push(<option value={value} key={dropDownMenu.length + 'listOptionAE'}>{textDictionary[value]}</option>)
        }
        return dropDownMenu;
    }

    function selectElement(selection) {
        setListSelection(selection.target.value);
    }

    function renderElements() {
        var renderedElements = [];
        var elementIndex = 0;

        for(const el of elements) {
            const currentIndex = elementIndex;
            renderedElements.push(
                <Col key={elements.length + 'ColAE' + currentIndex}>
                    <Card className="shadow border-0 py-5 px-5" style={{width:"100%"}}>
                        <FormBuilder
                            title={textDictionary[el['answerType']]}
                            template={activityElementTemplates[el['answerType']]}
                            custom_types={activityElementTemplates['custom_types']}
                            callBack={(key, value) => {
                                activityElementUpdateCallback(key, value, el['answerType'], index, currentIndex)
                            }}
                            initialValues={elements[currentIndex]}
                            keyIndex={index + '_' + currentIndex + '_'}
                            key={elements.length + 'FormBuilderAE'}
                        />
                    </Card>
                    <br></br>
                </Col>
            );
            elementIndex++;
        }
        //renderedElements.push(<Col key="submit"><br></br><input type="submit" value="Create Study"/></Col>);
        return renderedElements;
    }

    return (
        <>
            {renderElements()}
            <br></br>
            <Row className="justify-content-md-center">
                <Button
                    className="btn-neutral btn-icon"
                    color="default"
                    onClick={() => {
                            addNewElement(activityElementTemplates[listSelection])
                        }
                    }
                >
                    Add Activity Element
                </Button>
                <select 
                    onChange={selectElement} 
                    name="SelectElementList"
                    id = "SelectElementList"
                >
                    {renderElementOptions()}
                </select>
            </Row>
        </>
    );
}

export default ActivityElementBuilder;